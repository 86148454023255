/* You can add global styles to this file, and also import other style files */

html, body { 
    height: 100%;
    font-family: 'MyFont';
    overflow: none;
    scroll-behavior: smooth;
    color:#2A246A;
}
body { 
    margin: 0; 
    user-select: none;
    background-color: #33FFFF;
}

@font-face {
    font-family: 'MyFont';
    src: url('assets/fonts/coolvetica.OTF');
}

@font-face{
    font-family: 'orderFont';
    src: url('assets/fonts/RobotoCondensed-Regular.ttf');
  }



